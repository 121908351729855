











































































































































/* eslint-disable no-case-declarations */
import Vue from 'vue'

import Menu from '@/components/ui/Menu/Menu.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import Logos from '@/components/ui/Logos/Logos.vue'
import EventTime from '@/components/data/event/EventTime.vue'
import RainAlert from '@/components/data/forecast/rain/RainAlert.vue'
import DashboardSettings from '@/components/ui/DashboardSettings/DashboardSettings.vue'
import Timeline from '@/components/ui/Timeline/Timeline.vue'

import { vrsStore } from '@/store'
import preferences from '@/services/preferences'
import WeatherStationAverageTemperature from '@/components/data/weatherstation/AverageTemperature/AverageTemperature.vue'
import WeatherStationAverageTemperaturePopin from '@/components/data/weatherstation/AverageTemperature/Popin.vue'
import { vrsStoreWeatherStationAverageTemperature } from '@/store/avgtemp/store'
import { vrsStoreEvent } from '@/store/event/store'
import { convertDate, stringifyDate } from '@/helpers/dates'
import { defaultDashboard } from '@/helpers/defaults'
import { forecastDataExist } from '@/store/event/helpers'
import { PeriodOfInterest, Mode } from '@/components/ui/Timeline/D3Timeline/definitions'
import { AppQS } from '@/store/app/definitions'

export default Vue.extend({
  name: 'layout-header',
  components: {
    'ui-menu': Menu,
    'ui-loader': Loader,
    'ui-timeline': Timeline,
    'ui-logos': Logos,
    'forecast-rain-alert': RainAlert,
    'event-time': EventTime,
    'dashboard-settings': DashboardSettings,
    WeatherStationAverageTemperature,
    WeatherStationAverageTemperaturePopin
  },
  data () {
    return {
      stateEvent: vrsStore.state.event,
      stateConfig: vrsStore.state.config,
      stateApp: vrsStore.state.app,
      stateAuth: vrsStore.state.auth,
      stateForecast: vrsStore.state.forecast,
      stateTime: vrsStore.state.time,
      stateAverageTemperature: vrsStore.state.averageTemperature,
      displayAverageTemperaturePopin: false,
      displayDashboardSettings: false,
      newDashboard: {
        label: defaultDashboard.label,
        layout: [],
        settings: { ...defaultDashboard.settings }
      },
      headerReduced: false
    }
  },
  computed: {
    // selectedRangeLocal
    // currentTime
    ...vrsStore.modules.time.computed,
    withTimeline () {
      return this.$route.meta.withTimeline
    },
    timelineMode () {
      return this.$route.meta.timelineMode
    },
    logos: vrsStore.modules.config.computed.logosMobile,
    displayMenuOptions: vrsStore.modules.auth.computed.displayMenuOptions,
    displayHeaderRain: vrsStore.modules.auth.computed.displayHeaderRain,
    displayHeaderAverageTemperature: vrsStore.modules.auth.computed.displayHeaderAverageTemperature,
    displayEventDateSelector: vrsStore.modules.auth.computed.displayEventDateSelector,
    ...vrsStore.modules.time.computed,
    avgTempDisplayData () {
      /**
       * if we are on live, we use the latest data if we have one
       */
      if (
        vrsStore.modules.app.state.data.live &&
        vrsStore.state.averageTemperature.latest
      ) {
        return {
          average: vrsStore.state.averageTemperature.latest.average,
          value_date: vrsStore.state.averageTemperature.latest.value_date
        }
      } else if (vrsStore.state.averageTemperature.data) {
        /**
         * if we are on archive mode,
         * we display the data
         */
        return {
          average: vrsStore.state.averageTemperature.data.average,
          value_date: vrsStore.state.averageTemperature.data.value_date
        }
      }
      return {
        average: null,
        value_date: null
      }
    },
    userDashboards: {
      get () {
        return this.stateAuth?.data?.user?.dashboards || []
      },
      async set (newDashboards) {
        await vrsStore.modules.auth.actions.saveDashboards(newDashboards)
      }
    },
    allDataTimeRangesLocal: vrsStore.computed.allDataTimeRangesLocal
  },
  methods: {

    /**
     * Update the selected range in URL (referenceDate, length)
     * Then, the router will update storeApp accordingly
     * and the selectedRangeLocal will be updated in store
     * and will be dispatched to all the components that need it
     */
    updateSelectedRangeLocal (newRange: [string, string], isLive: boolean) {
      const from = convertDate(newRange[0])
      const to = convertDate(newRange[1])
      const length = Math.round(to.diff(from, 'minutes').minutes)
      const query: AppQS = { ...this.$route.query, length }
      if (!isLive) {
        query.referenceDate = newRange[1]
      } else {
        delete query.referenceDate
      }
      this.$router.push({
        path: this.$route.path,
        query
      })
    },

    updateZoom (newZoom) {
      if (!newZoom) return
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          zoomTimeline: newZoom
        }
      }).catch(() => {
      })
    },

    goLive () {
      const newRoute = {
        path: this.$route.path,
        query: {
          ...this.$route.query
        }
      }
      delete newRoute.query.mode
      /**
     * if the event is an active one,
     * we just remove the referenceDate,
     * that will compute (in store, later)
     * the good reference date, for a live event
     */
      if (this.stateEvent.data.active) {
        delete newRoute.query.referenceDate
      } else {
      /**
       * if the event is not an active one,
       * we try to go to the latest date available
       */
        if (this.stateForecast.apiData?.availableRanges?.data?.length > 0) {
          newRoute.query.referenceDate = this.stateForecast.apiData.availableRanges.data[
            this.stateForecast.apiData.availableRanges.data.length - 1
          ][1]
        } else {
        /**
         * if there is no one, we just go to the latest date of the event period
         */
          newRoute.query.referenceDate = this.stateEvent.data.dateRange[1]
        }
      }
      this.$router
        .push(newRoute)
        .catch(() => {
        })
    },

    goPast () {
      const newRoute = {
        path: this.$route.path,
        query: {
          ...this.$route.query
        }
      }
      /**
     * if the event is not an active one,
     * we remove the referenceDate,
     * that will compute the right one (in store, later)
     * to the first available data
     */
      if (!this.stateEvent.data.active) {
        delete newRoute.query.referenceDate
      } else {
      /**
       * else we are on an active one,
       * so we try to find the first data date,
       * or we set to the first date of the event period
       */
        if (this.stateForecast.apiData?.availableRanges?.data?.length > 0) {
          newRoute.query.referenceDate = this.stateForecast.apiData.availableRanges.data[0][0]
        } else {
          newRoute.query.referenceDate = this.stateEvent.data.dateRange[0]
        }
      }
      this.$router
        .push(newRoute)
        .catch(() => {
        })
    },

    goSession (session: PeriodOfInterest) {
      switch (this.timelineMode) {
        case Mode.Range:
          const startDate = convertDate(session.start)
          const endDate = convertDate(session.end)
          const length = endDate.diff(startDate).as('minutes') + 60
          const referenceDate = stringifyDate(endDate.plus({ minutes: 30 }))
          this.$router.push({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              length,
              referenceDate
            }
          })
          break
        case Mode.Single:
          this.$router.push({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              referenceDate: session.start
            }
          })
          break
      }
    },

    async logout () {
      await vrsStore.modules.auth.actions.logout()
      this.$matomo && this.$matomo.resetUserId()
      preferences.lastURL = null
      this.$router.push('/')
    },
    changeCurrentEvent (args) {
      if (vrsStore.modules.auth.computed.canAccessToForecastClimate()) {
        if (forecastDataExist(args, this.stateEvent.events)) {
          return this.$router.push('/event/' + args + '/forecast')
        }
        return this.$router.push('/event/' + args + '/climaterecap')
      }
      this.$router.push('/event/' + args + '/forecast')
    },
    updateReferenceDate (newValue) {
      if (this.$route.query.referenceDate === newValue) return
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          referenceDate: newValue,
          mode: 'archive'
        }
      })
      vrsStore.state.app.data.referenceDate = newValue
    },
    updateReferenceDateFromTimeline (newValue) {
      if (this.timelineMode === 'Single') this.updateReferenceDate(newValue)
    },

    toggleLive () {
      // if we are live, we go to archive
      if (vrsStore.state.app.data.live) {
        // go to archive mode
        this.$router.push({
          path: this.$route.path,
          query: {
            referenceDate: vrsStore.state.app.data.referenceDate
          }
        })
      } else {
        // go live
        this.$router.push({ path: this.$route.path })
      }
    },
    toggleAverageTemperaturePopin () {
      this.displayAverageTemperaturePopin = !this.displayAverageTemperaturePopin
    },
    onExportAvgTemp ({ to, from }: { to: string; from: string}) {
      vrsStoreWeatherStationAverageTemperature.actions.exportCSV(
        vrsStoreEvent.state.data.id,
        convertDate(from + '000000'),
        convertDate(to + '235959')
      )
    },
    /**
     * Display a dialog UI
     * to define some properties of the dashboard :
     * * title
     * * display or not a map
     */
    addDashboardPage () {
      /**
       * init the new dashboard
       */
      this.newDashboard = {
        label: defaultDashboard.label,
        layout: [],
        settings: { ...defaultDashboard.settings }
      }

      this.displayDashboardSettings = true
    },

    async updateDashboardLabel (dashboardItem) {
      const dashboards = [
        ...vrsStore.modules.auth.state.data.user.dashboards
      ]
      dashboards[dashboardItem.id].label = dashboardItem.label
      await vrsStore.modules.auth.actions.saveDashboards(dashboards)
    },

    /**
     * Save dashboard then open it
     */
    async saveDashboard () {
      const dashboards = [
        ...vrsStore.modules.auth.state.data.user.dashboards
      ]
      dashboards.push(this.newDashboard)
      await vrsStore.modules.auth.actions.saveDashboards(dashboards)
      this.displayDashboardSettings = false
      this.$router.push('/event/' + (this.eventId || 'active') + '/dashboard/' + (vrsStore.state.auth.data.user.dashboards.length - 1))
    },

    async removeDashboard (dashboardIndex) {
      const dashboards = [
        ...vrsStore.modules.auth.state.data.user.dashboards
      ]
      dashboards.splice(dashboardIndex, 1)
      await vrsStore.modules.auth.actions.saveDashboards(dashboards)
      this.$router.push(vrsStore.modules.auth.computed.defaultHomePage())
    },

    toggleHeader () {
      this.headerReduced = !this.headerReduced
    }
  }
})
