/* eslint-disable no-console */
import { DateTime } from 'luxon'
import { createStore } from 'vue-reactive-store'

import { AppQS, AppRoute } from './app/definitions'

import { vrsStoreEvent, fetchEvent } from './event/store'
import { vrsStoreTime } from './time/store'
import { vrsStoreConfig } from './config/store'
import { vrsStoreAuth } from './auth/store'
import { vrsStoreApp } from './app/store'
import { vrsStoreForecast, fetchForecastData, computeForecastDataToDisplay } from './forecast/store'
import { vrsStoreInformation, fetchInfosData, computeInfosDataToDisplay } from './infos/store'
import { fetchWeatherStationDataLatest, fetchWeatherStationDataLatestForOneStation, fetchWeatherStationDataLatestFilteredByDate, vrsStoreWeatherStation } from './weatherstation/store'
import { vrsStoreMessages, fetchMessages } from './message/store'
import { vrsStoreWeatherStationAverageTemperature, fetchWeatherStationAverageTemperatureDataLatest, fetchWeatherStationAverageTemperatureDataFilteredByDate } from './avgtemp/store'

import { convertDate, stringifyDate, getNowDateTime } from '@/helpers/dates'
import crossbarService, { CrossbarDataType } from '@/services/crossbar'
import preferences from '@/services/preferences'

const debug = false

function getDefaultReferenceDateLuxon (route: AppRoute) {
  // console.trace('[getDefaultReferenceDateLuxon] begin')
  if (!vrsStoreEvent.state.data?.dateRange) return null
  /**
   * Here, we do not have a referenceDate,
   * and we have already (normally) time data
   * so, we normally know what is the last radar image available
   */
  let referenceDateLuxon = convertDate(
    vrsStoreEvent.state.data?.dateRange?.[1],
    vrsStoreEvent.state.data?.timezone
  )
  debug && console.log('[getDefaultReferenceDateLuxon] actual date luxon', stringifyDate(referenceDateLuxon))
  switch (route) {
    case AppRoute.FORECAST:
      debug && console.log('[getDefaultReferenceDateLuxon] getting default date for forecast route', vrsStoreForecast.state.apiData?.range)
      if (vrsStoreForecast.state.apiData?.range?.[0]) {
        /**
         * we set it to the first forecast data
         */
        referenceDateLuxon = convertDate(
          vrsStoreForecast.state.apiData.range[0],
          vrsStoreEvent.state.data.timezone
        )
      } else {
        /**
         * in last step, for FORECAST route, it's the first datetime
         */
        referenceDateLuxon = convertDate(
          vrsStoreEvent.state.data.dateRange[0],
          vrsStoreEvent.state.data.timezone
        )
      }
      break
    case AppRoute.NOWCASTING:
      /**
       * we set it to the last radar image available,
       * 'cause the user didn't change the date !
       */
      if (vrsStoreEvent.state.data.radarActivityPeriods?.length > 0) {
        referenceDateLuxon = DateTime.fromISO(
          vrsStoreEvent.state.data.radarActivityPeriods[
            vrsStoreEvent.state.data.radarActivityPeriods.length - 1
          ][1]
        ).setZone(vrsStoreEvent.state.data.timezone)
      }
      break
    case AppRoute.OBSERVATIONS:
    case AppRoute.OBSERVATIONS_DETAILS:
      debug && console.log('[getDefaultReferenceDateLuxon] we are here', vrsStoreEvent.state.data.stationsActivityPeriods)
      if (vrsStoreEvent.state.data.stationsActivityPeriods?.length > 0) {
        /**
         * we set it to the last observation date known,
         */
        referenceDateLuxon = convertDate(
          vrsStoreEvent.state.data.stationsActivityPeriods[
            vrsStoreEvent.state.data.stationsActivityPeriods.length - 1
          ][1],
          vrsStoreEvent.state.data.timezone
        )
      }
      break
  }
  debug && console.log('[getDefaultReferenceDateLuxon] end', stringifyDate(referenceDateLuxon))
  return referenceDateLuxon
}

/**
 * This store is the merge of all sub-store
 * Here we can watch all dependencies to react and fetch data
 * These are the side-effects (or effects in MobX style)
 */
export const vrsStore = createStore({
  name: 'mfs-store',
  modules: {
    app: vrsStoreApp,
    auth: vrsStoreAuth,
    config: vrsStoreConfig,
    forecast: vrsStoreForecast,
    event: vrsStoreEvent,
    information: vrsStoreInformation,
    message: vrsStoreMessages,
    time: vrsStoreTime,
    weatherStation: vrsStoreWeatherStation,
    averageTemperature: vrsStoreWeatherStationAverageTemperature
  },
  computed: {
    /**
     * This method build an array of all data ranges to display.
     * Forecast, Radar & Observation data.
     */
    allDataTimeRangesLocal () {
      const result = []
      if (vrsStoreForecast.state.apiData?.availableRanges) {
        result.push(vrsStoreForecast.state.apiData.availableRanges)
      }
      result.push({
        type: 'observation',
        name: 'Ground stations data',
        data: vrsStoreEvent.state.data?.stationsActivityPeriods || []
      })
      result.push(vrsStoreTime.computed.availableTimeRangesLocal())
      return result
    }
  },
  watch: {
    /**
     * When eventId change,
     * we have to fetch the new event,
     * and all data related to it
     */
    async 'app.data.eventId' (newValue: number | string, oldValue: number | string) {
      debug && console.log('[app.data.eventId] start')
      if (newValue !== oldValue) {
        /**
         * we init some sub stores
         */
        vrsStoreTime.actions.resetState()
        vrsStoreEvent.state.data = null
        vrsStoreEvent.state.sessionsWithStat = null
        vrsStoreForecast.actions.resetState()
        vrsStoreWeatherStation.actions.resetState()
        vrsStoreWeatherStationAverageTemperature.actions.resetState()

        try {
          // fetch the event and store it in vrsStoreEvent.state.data
          await vrsStoreEvent.actions.fetchEvent(newValue)
          // fetch the event and store it in vrsStoreEvent.state.data
          await vrsStore.modules.event.actions.fetchEventWithStatisticData()
          vrsStoreApp.state.data.eventTimezone = vrsStoreEvent.state.data?.timezone
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          return
        }
        let referenceDateLuxon
        /**
         * if event is active,
         * we can set the live to true
         * if no referenceDate is available (in URL, thanks to vue-router)
         */
        if (vrsStoreEvent.state.data?.active) {
          /**
           * if there is a referenceDate (in URL),
           * we keep it and say we aren't anymore in live
           */
          if (vrsStoreApp.state.data.referenceDate) {
            vrsStoreApp.state.data.live = false
            referenceDateLuxon = convertDate(vrsStoreApp.state.data.referenceDate, vrsStoreEvent.state.data.timezone)
          } else {
            /**
             * if not, we are live !
             * so, no referenceDate available
             */
            vrsStoreApp.state.data.live = true
            referenceDateLuxon = getNowDateTime().setZone(vrsStoreEvent.state.data.timezone)
            /**
             * Beware, sometimes we are on the active event, but after the latest time available
             * we have to set it 'max' to the latest time available
             */
          }
        } else {
          /**
           * event is in the past
           * at this moment, we don't have any forecast / weatherstation / whatever data for this event,
           * we trigger this later.
           * Fetching the forecast data will trigger another watcher on apiData,
           * that will normally set the referenceDate
           */
          debug && console.log('[app.data.eventId] event is in the past')
          vrsStoreApp.state.data.live = false
          if (!vrsStoreApp.state.data.referenceDate) {
          //   referenceDateLuxon = convertDate(vrsStoreEvent.state.data.dateRange[1], vrsStoreEvent.state.data.timezone)
          } else {
            referenceDateLuxon = convertDate(vrsStoreApp.state.data?.referenceDate, vrsStoreEvent.state.data?.timezone)
          }
        }
        if (referenceDateLuxon) {
          debug && console.log('[app.data.eventId] reference date', stringifyDate(referenceDateLuxon))
          vrsStoreApp.state.data.referenceDate = stringifyDate(referenceDateLuxon)
          // const from = referenceDateLuxon.minus({
          //   minutes: vrsStoreApp.state.data.animationLength || vrsStoreApp.state.data.defaultAnimationLength
          // })
          // vrsStoreTime.state.selectedRangeLocal = [
          //   stringifyDate(from),
          //   vrsStoreApp.state.data.referenceDate
          // ]
          if (vrsStore.modules.auth.computed.displayHeaderAverageTemperature()) {
            debug && console.log('[app.data.eventId] display header average enabled => loading average temp')
            fetchWeatherStationAverageTemperatureDataFilteredByDate(
              vrsStoreEvent.state.data.id,
              referenceDateLuxon.minus({ minutes: 30 }),
              referenceDateLuxon
            )
          }
          fetchWeatherStationDataLatestFilteredByDate(
            vrsStoreEvent.state.data.stations.map(s => s.id.toString()),
            referenceDateLuxon
          )
        } else {
          debug && console.log('[app.data.eventId] no reference date, loading weather station data')
          fetchWeatherStationDataLatest(vrsStoreEvent.state.data.stations.map(s => s.id.toString()))
          if (vrsStore.modules.auth.computed.displayHeaderAverageTemperature()) {
            debug && console.log('[app.data.eventId] display header average enabled => loading latest average temp')
            fetchWeatherStationAverageTemperatureDataLatest(vrsStoreEvent.state.data.id)
          }
        }
        fetchForecastData(vrsStoreEvent.state.data?.id, vrsStoreEvent.state.data?.timezone)
        fetchInfosData(vrsStoreEvent.state.data.id, vrsStoreApp.state.data.referenceDate, vrsStoreApp.state.data.live, vrsStoreEvent.state.data.timezone)
        fetchMessages(vrsStoreEvent.state.data.id)
      }
    },
    /**
     * queryString evolve each time the URL is updated
     * * for referenceDate
     * * length
     * * eventId
     * * or other params that could live in the URL
     */
    'app.data.queryString' (newValue: AppQS, oldValue: AppQS) {
      debug && console.log('[app.data.queryString] watcher')
      vrsStoreApp.state.data.referenceDate = newValue.referenceDate
      vrsStoreApp.state.data.animationLength = newValue.length || vrsStoreApp.state.data.currentPageDefaultAnimationLength || preferences.defaultAnimationLength
      vrsStoreApp.state.data.zoomTimeline = newValue.zoomTimeline
      /**
       * if eventIds are different, we store it in app.data
       * and this trigger the loading of all data related to this event
       */
      const newEventId = newValue.eventId ? newValue.eventId.toString() : null
      const oldEventId = oldValue.eventId ? oldValue.eventId.toString() : null
      if (newEventId !== oldEventId) {
        debug && console.log('[app.data.queryString] new event detected')
        /**
         * we pass in the watcher above, related to 'app.data.eventId'
         * it helps populating the store with the event data
         */
        vrsStoreApp.state.data.eventId = newEventId
      } else {
        /**
         * We are on the same event
         * than the previous route navigation,
         * so we know that vrsStoreEvent.state.data is populated with the right event
         */
        let referenceDateLuxon
        /**
         * If there is a referenceDate, we store it in app.data
         * and this will trigger the update of displayed data
         */
        if (newValue.referenceDate) {
          vrsStoreApp.state.data.live = false
          /**
           * we compute selectedRangeLocal without timezone,
           * 'cause we don't need it, we work with both timezone datestring
           */
          referenceDateLuxon = convertDate(newValue.referenceDate, vrsStoreEvent.state.data.timezone)
        } else {
          /**
           * else if we are on the active event,
           * => we go live ! (same if we were already live)
           */
          if (vrsStoreEvent.state.data && vrsStoreEvent.state.data.active) {
            vrsStoreApp.state.data.live = true
            referenceDateLuxon = getNowDateTime().setZone(vrsStoreEvent.state.data.timezone)
          } else {
            referenceDateLuxon = getDefaultReferenceDateLuxon(newValue.route)
          }
          debug && console.log('[app.data.queryString] new reference date luxon : ', vrsStoreApp.state.data.referenceDate)
          const from = referenceDateLuxon.minus({ minutes: newValue.length || vrsStoreApp.state.data.defaultAnimationLength })
          vrsStoreTime.state.selectedRangeLocal = [stringifyDate(from), vrsStoreApp.state.data.referenceDate]
        }
        vrsStoreApp.state.data.referenceDate = stringifyDate(referenceDateLuxon)
      }
    },
    /**
     * When Forecast API data change,
     * we have to refresh the data we want to display,
     * according to the referenceDate
     */
    'forecast.apiData' (newValue) {
      if (!newValue) return
      /**
       * We are live !
       * We want fresh data
       */
      debug && console.log('forecast apiData has changed !')
      let referenceDateLuxon
      if (vrsStoreApp.state.data.live) {
        referenceDateLuxon = getNowDateTime().setZone(vrsStoreEvent.state.data.timezone)
      } else if (vrsStoreApp.state.data.referenceDate) {
        /**
         * We are in archive mode,
         * we want the data at the reference date
         */
        referenceDateLuxon = convertDate(vrsStoreApp.state.data.referenceDate, vrsStoreEvent.state.data.timezone)
      } else {
        referenceDateLuxon = getDefaultReferenceDateLuxon(vrsStoreApp.state.data.queryString.route)
      }
      const newReferenceDate = stringifyDate(referenceDateLuxon)
      /**
       * if new & old referenceDate are equal,
       * it's a caveat of Vue, it won't detect the change
       * => we have to trigger the computeForecastDataToDisplay
       */
      if (newReferenceDate && newReferenceDate === vrsStoreApp.state.data.referenceDate) {
        computeForecastDataToDisplay(
          referenceDateLuxon,
          vrsStoreEvent.state.data.timezone,
          vrsStoreEvent.state.data.sessions
        )
      } else {
        vrsStoreApp.state.data.referenceDate = newReferenceDate
      }
    },
    /**
     * When the referenceDate change,
     * we have to update the displayed data,
     * if event is loaded.
     * Else it would be done by the app.data.eventId trigger
     */
    'app.data.referenceDate' (newDate) {
      debug && console.log('[app.data.referenceDate] start', newDate)
      if (!newDate) return
      if (vrsStoreEvent.state.data) {
        /**
         * We are live !
         * We want fresh data
         */
        let referenceDateLuxon: DateTime
        if (vrsStoreApp.state.data.live) {
          referenceDateLuxon = getNowDateTime().setZone(vrsStoreEvent.state.data.timezone)
        } else {
          /**
           * We are in archive mode,
           * we want the data at the reference date
           */
          referenceDateLuxon = convertDate(newDate, vrsStoreEvent.state.data.timezone)
        }
        const referenceDate = stringifyDate(referenceDateLuxon)
        fetchWeatherStationDataLatestFilteredByDate(
          vrsStoreEvent.state.data.stations.map(s => s.id.toString()),
          referenceDateLuxon
        )
        computeForecastDataToDisplay(
          referenceDateLuxon,
          vrsStoreEvent.state.data.timezone,
          vrsStoreEvent.state.data.sessions
        )
        computeInfosDataToDisplay(referenceDate)
        fetchWeatherStationAverageTemperatureDataFilteredByDate(
          vrsStoreEvent.state.data?.id,
          referenceDateLuxon.minus({ minutes: 30 }),
          referenceDateLuxon
        )
      }
    }

  }
})

export function fetchForecastDataGlobal () {
  if (!vrsStoreEvent.state.data?.id) return
  fetchForecastData(vrsStoreEvent.state.data?.id, vrsStoreEvent.state.data?.timezone)
}

crossbarService.on(CrossbarDataType.FORECAST_HOURLY, () => {
  fetchForecastData(vrsStoreEvent.state.data?.id, vrsStoreEvent.state.data?.timezone)
})

crossbarService.on(CrossbarDataType.FORECAST_DAILY, () => {
  fetchForecastData(vrsStoreEvent.state.data?.id, vrsStoreEvent.state.data?.timezone)
})

crossbarService.on(CrossbarDataType.FORECAST_RAIN, () => {
  fetchForecastData(vrsStoreEvent.state.data?.id, vrsStoreEvent.state.data?.timezone)
})

crossbarService.on(CrossbarDataType.RADAR, (eventId?: number) => {
  /**
   * We reload event data, with radar activity period,
   * only if the eventId is the same than the event the user is on
   */
  (eventId === vrsStoreEvent.state.data?.id) &&
  vrsStoreEvent.actions.fetchEvent(vrsStoreEvent.state.data?.id, true)
})

crossbarService.on(CrossbarDataType.STATION_DATA, (stationId?: number) => {
  if (stationId) fetchWeatherStationDataLatestForOneStation(stationId)
  else { fetchWeatherStationDataLatest(vrsStoreEvent.state.data?.stations.map(s => s.id.toString())) }
})

crossbarService.on(CrossbarDataType.INFO, () => {
  fetchInfosData(vrsStoreEvent.state.data.id, null, true, vrsStoreEvent.state.data.timezone)
})

crossbarService.on(CrossbarDataType.MESSAGE, () => {
  fetchMessages(vrsStoreEvent.state.data.id)
})

crossbarService.on(CrossbarDataType.RADAR_PERIODS, () => {
  fetchEvent(vrsStoreEvent.state.data?.id, true)
})

crossbarService.on(CrossbarDataType.AVERAGE_TEMPERATURE, () => {
  fetchWeatherStationAverageTemperatureDataLatest(vrsStoreEvent.state.data.id)
})

crossbarService.on(CrossbarDataType.EVENT_SESSION_OVER, () => {
  vrsStoreEvent.actions.fetchEventWithStatisticData()
})
