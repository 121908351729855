/* eslint-disable no-case-declarations */
import { DateTime } from 'luxon'
import { Prevision, ForecastData, ForecastDataFromAPI, ForecastDataHourly, ForecastDataDaily, DayData, HourlyData } from './definitions'
import { stringifyDate, convertDate } from '@/helpers/dates'
import { WeatherEventSession } from '../event/definitions'
import { D3TimelineSerie } from '@/components/ui/Timeline/D3Timeline/timeline'

/**
 * Get data for one day for hourly forecast data
 */
export function extractDataForOneDay (prevision: Prevision, daysIndex: number, timezone: string, dateTo, config: any): DayData {
  let previousNight = ''
  let weatherConditions = ''
  let updateTime = ''
  let day = ''
  let dd = ''
  let data: HourlyData[] = []
  let datePrevision: DateTime
  let isMessage = false
  let message

  if (prevision['@date']) {
    const date = DateTime.fromFormat(prevision['@date'].toString(), 'yyyyMMddHHmmss')
    // updateTime = date.toFormat('dddd MMMM dd, HH:mm');
    // updateTime = date.toFormat('T \'on\' EEE dd MMM') // 13:00 on Thu 19 Apr
    updateTime = date.toFormat('T \'on\' EEE dd MMM') // 13:00 on Thu 19 Apr
    // day = date.toFormat('EEE MMM dd').toUpperCase();
  }

  if (prevision.section) {
    if (prevision.section[0] && prevision.section[0].nuitprec && prevision.section[0].nuitprec.$) {
      previousNight = prevision.section[0].nuitprec.$.split('--')
    }

    if (prevision.section[0] && prevision.section[0].ww && prevision.section[0].ww.$) {
      weatherConditions = prevision.section[0].ww.$.split('--')
    }

    const currentLocalDateTime = DateTime.local().setZone(timezone)
    const currentLocalDate = dateTo || DateTime.local().setZone(timezone)

    if (prevision.section[1] && prevision.section[1].jour) {
      data = prevision.section[1].jour.reduce(function (previousValue, currentValue, index) {
        datePrevision = DateTime.fromFormat(currentValue['@date'].toString(), 'yyyyMMddHHmmss', { zone: timezone, setZone: true })
        day = datePrevision.toFormat('EEE MMM dd')
        dd = datePrevision.toFormat('dd')
        if (currentLocalDate <= datePrevision) {
          previousValue.push({
            hour: datePrevision.toFormat('T'),
            day: day,
            date: currentValue['@date'].toString(),
            daysIndex: daysIndex,
            firstOfTheDay: index === 0,
            lastOfTheDay: index === prevision.section[1].jour.length - 1,
            active: datePrevision === currentLocalDateTime,
            prevealingWeather: currentValue.codeww ? currentValue.codeww.$ : '',
            chanceOfRain: currentValue.rr ? currentValue.rr.$ : '',
            isChanceOfRainOverLimit: currentValue.rr ? config.LIMIT_FORECAST_HOURLY_RAIN_CHANCE.indexOf(currentValue.rr.$) >= 0 : false,
            temperature: currentValue.tempe ? currentValue.tempe.$ : '',
            isTemperatureOverLimit: currentValue.tempe ? currentValue.tempe.$ >= config.LIMIT_FORECAST_HOURLY_TEMPERATURE_AIR : '',
            trackMinMax: currentValue.tpistemin ? currentValue.tpistemin.$ + '/' + currentValue.tpistemax.$ : '',
            trackMin: currentValue.tpistemin ? currentValue.tpistemin.$ : '',
            trackMax: currentValue.tpistemax ? currentValue.tpistemax.$ : '',
            isTrackMinOverLimit: currentValue.tpistemin ? currentValue.tpistemin.$ >= config.LIMIT_FORECAST_HOURLY_TEMPERATURE_TRACK_MIN : false,
            isTrackMaxOverLimit: currentValue.tpistemax ? currentValue.tpistemax.$ >= config.LIMIT_FORECAST_HOURLY_TEMPERATURE_TRACK_MAX : false,
            wind: currentValue.ddwind ? currentValue.ddwind.$ : '',
            average: currentValue.ff ? currentValue.ff.$ : '',
            isAverageOverLimit: config.LIMIT_FORECAST_HOURLY_WIND_AVERAGE.indexOf(currentValue.ff.$) >= 0,
            gusts: currentValue.fx ? currentValue.fx.$ : '',
            isGustsOverLimit: currentValue.fx ? config.LIMIT_FORECAST_HOURLY_WIND_GUSTS.indexOf(currentValue.fx.$) >= 0 : false,
            humidity: currentValue.U ? currentValue.U.$ : '',
            isHumidityOverLimit: currentValue.U ? currentValue.U.$ >= config.LIMIT_FORECAST_HOURLY_HUMIDITY : false,
            pressure: currentValue.psol ? currentValue.psol.$ : '',
            isPressureOverLimit: currentValue.psol ? currentValue.psol.$ >= config.LIMIT_FORECAST_HOURLY_PRESSURE : false,
            session: currentValue.session ? currentValue.session.$ : ''
          })
        }
        return previousValue
      }, [])
    }
  } else if (prevision.message) {
    isMessage = true
    message = prevision.message.$
  }

  if (data.length > 0 || isMessage === true) {
    return {
      previousNight,
      weatherConditions,
      updateTime,
      day,
      dd,
      data,
      date: datePrevision,
      isMessage,
      message
    }
  } else {
    return null
  }
}

/**
 *
 * @param responseData
 * @param timezone
 * @param dateTo
 * @param config
 */
export function analyzeResponseForecastHourly (responseData, timezone, dateTo, sessions: Array<WeatherEventSession>, config) {
  const forecastHourly: Array<DayData> = []
  let flattenHourlyData: Array<HourlyData> = []
  const days = []

  function checkDateForOneDay (day) {
    return (day.data &&
      day.data.prevision &&
        day.data.prevision.section &&
        day.data.prevision.section[1] &&
        day.data.prevision.section[1].jour &&
        day.data.prevision.section[1].jour[0] &&
        day.data.prevision.section[1].jour[0]['@date'])
  }

  function getDateForOneDay (day) {
    return day.data.prevision.section[1].jour[0]['@date'].toString()
  }

  responseData.sort(function (a, b) {
    if (checkDateForOneDay(a) && checkDateForOneDay(b)) {
      const dateA = getDateForOneDay(a)
      const dateB = getDateForOneDay(b)
      if (dateA < dateB) {
        return -1
      }
      if (dateA > dateB) {
        return 1
      }
    }
    return 0
  })

  responseData.forEach((day) => {
    if (day.data.prevision) {
      const dayData: DayData = extractDataForOneDay(
        day.data.prevision,
        forecastHourly.length,
        timezone,
        dateTo,
        config
      )
      if (dayData) {
        forecastHourly.push(dayData)
      }
    }
  })

  forecastHourly.forEach((dayData, index/*, initialArray */) => {
    // let currentFlattenDataIndex = ( ( index === initialArray.length - 1 )
    //   ? flattenHourlyData.length + dayData.data.length - 1
    //   : flattenHourlyData.length );
    // first, we want to know if a session will start between the current data
    // and the next data
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let nextHourlyData
    if (index < forecastHourly.length - 1) {
      nextHourlyData = forecastHourly[index + 1]
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const laps = dayData.date
    }
    const currentFlattenDataIndex = flattenHourlyData.length
    flattenHourlyData = flattenHourlyData.concat(dayData.data)
    days.push({
      label: dayData.dd,
      index: index,
      flattenDataIndex: currentFlattenDataIndex
    })
  })

  /**
   * we add information concerning session,
   * this will help us to display a div.Session on ForecastHourly
   * see http://localhost:8080/#/event/50/forecast?referenceDate=20190131132323&mode=archive
   */
  sessions.forEach(currentSession => {
    const sessionDateStart = currentSession.start.substring(0, 8)
    const sessionDateEnd = currentSession.end.substring(0, 8)

    const sessionLuxonDateStart = DateTime.fromISO(sessionDateStart)
    const sessionLuxonDateEnd = DateTime.fromISO(sessionDateEnd)
    const sessionNumberOfDays = sessionLuxonDateEnd.diff(sessionLuxonDateStart, 'days').toObject().days

    /**
     * If session is across several days
     * we need to loop over each day of the session period
     */
    for (let i = 0; i <= sessionNumberOfDays; i++) {
      const currentSessionDate = sessionLuxonDateStart.plus({ days: i }).toFormat('yyyyMMdd')
      const currentSessionDay = forecastHourly.find(dayData => {
        return dayData.date.toFormat('yyyyMMdd') === currentSessionDate
      })
      if (!currentSessionDay) continue
      /**
       * if session is accross several days,
       * we check the index day to know if the session has already begun
       */
      let sessionHourStartIndex
      if (sessionNumberOfDays > 0 && i > 0) {
        sessionHourStartIndex = 0
      } else {
      /**
       * we search the first hour that is >= of the currentSession.start
       * if we have a start at 12:30, it'll be eg 13:00
       * but we have to start the display at 12:00, before the session start, not after
       */
        sessionHourStartIndex = currentSessionDay.data.findIndex(hourlyData => hourlyData.date >= currentSession.start)
      }

      /**
       * If no matching, the session is at the end of the data
       */
      if (sessionHourStartIndex === -1) {
        sessionHourStartIndex = currentSessionDay.data.length - 1
      } else if (currentSessionDay.data[sessionHourStartIndex].date !== currentSession.start && sessionHourStartIndex > 0) {
        /**
         * so now, we check that if it's equal, we continue to the following check,
         * if not, we check that a previous hour exist, and so it will be the sessionHour reference
         */
        sessionHourStartIndex--
      }
      const sessionHour = currentSessionDay.data[sessionHourStartIndex]
      /**
       * if session is accross several days,
       * we check the index day to know if the session is not at the end
       */
      let sessionHourEndIndex = -1
      let sessionHourIsAtTheEnd = false
      if (!(sessionNumberOfDays > 0 && i < sessionNumberOfDays)) {
        /**
         * now we compute the size of the session, based on the session end
         * we search the first hour >= session end
         * if we don't find one, we consider the session will end in the last hour available
         */
        sessionHourEndIndex = currentSessionDay.data.findIndex(hourlyData => hourlyData.date >= currentSession.end)
      }
      /**
       * Is the session hour end at the end of hourly data for the current day ?
       */
      if (sessionHourEndIndex === -1) {
        sessionHourEndIndex = currentSessionDay.data.length - 1
        sessionHourIsAtTheEnd = true
      }
      /**
       * if the start hour selected is > currentSesssion.end
       * and the end hour selected is > to currentSession.end,
       * the session is over and before the period we are displaying
       * => we return
       */
      if (
        currentSessionDay.data[sessionHourStartIndex].date >= currentSession.end &&
        currentSessionDay.data[sessionHourEndIndex].date > currentSession.end
      ) {
        return
      }
      sessionHour.displaySession = true
      /**
       * the size of the session is the difference between the two indexes found
       */
      let sessionSize = 1
      if (sessionHourIsAtTheEnd) {
        sessionSize = currentSessionDay.data.length - sessionHourStartIndex
      } else if (sessionHourEndIndex !== sessionHourStartIndex) {
        sessionSize = sessionHourEndIndex - sessionHourStartIndex
      }
      sessionHour.sessionSize = sessionSize
      sessionHour.sessionLabel = currentSession.label
    }
  })

  return {
    data: forecastHourly,
    flattenData: flattenHourlyData,
    days
  }
}

/**
 * Parse Forecast Daily data
 */
export function analyzeResponseForecastDaily (prevision: Prevision, timezone: string, currentLocalDate: DateTime, config: any) {
  let updateTime = ''
  let day = ''
  let data = []
  let header = ''
  const date = convertDate(prevision['@date'].toString(), timezone) // DateTime.fromFormat(, 'yyyyMMddHHmmss', { zone: timezone, setZone: true })
  // updateTime = date.toFormat('T \'on\' EEE dd MMM') // 13:00 on Thu 19 Apr
  updateTime = date.toFormat('T \'on\' EEE dd MMM') // 13:00 on Thu 19 Apr
  day = date.toFormat('EEE MMM dd')

  if (prevision.section) {
    if (prevision.section[1] && prevision.section[1].jour) {
      data = prevision.section[1].jour.reduce(function (previousValue, currentValue) {
        const datePrevision = convertDate(currentValue['@date'].toString() + '235959', timezone) // DateTime.fromFormat(currentValue['@date'].toString(), 'yyyyMMdd').set({ hour: 23, minute: 59, second: 59 })
        if (currentLocalDate <= datePrevision) {
          previousValue.push({
            day: datePrevision.toFormat('EEE MMM dd'),
            observation: currentValue.wwquot ? currentValue.wwquot.$.split('--') : [null],
            temperatureMin: currentValue.tn ? currentValue.tn.$ : null,
            isTemperatureMinOverLimit: currentValue.tn?.$ >= config.LIMIT_FORECAST_BYDAY_TEMPERATURE_MIN,
            temperatureMax: currentValue.tx ? currentValue.tx.$ : null,
            isTemperatureMaxOverLimit: currentValue.tx?.$ >= config.LIMIT_FORECAST_BYDAY_TEMPERATURE_MAX,
            weatherMorning: currentValue.codewwam ? currentValue.codewwam.$ : null,
            weatherAfternoon: currentValue.codewwpm ? currentValue.codewwpm.$ : null,
            rain: currentValue.rr ? currentValue.rr.$ : null,
            isRainOverLimit: currentValue.rr ? config.LIMIT_FORECAST_BYDAY_RAIN_CHANCE.indexOf(currentValue.rr.$) >= 0 : false,
            average: currentValue.ff ? currentValue.ff.$ : null,
            isAverageOverLimit: config.LIMIT_FORECAST_BYDAY_WIND.indexOf(currentValue.ff.$) >= 0,
            wind: currentValue.ddwind ? currentValue.ddwind.$ : null,
            pressure: currentValue.psol ? currentValue.psol.$ : null
          })
        }
        return previousValue
      }, [])
    }

    if (prevision.section[0] && prevision.section[0].sitgene) {
      header = prevision.section[0].sitgene.$.split('--')
    }
  } else if (prevision.message && prevision.message.$) {
    data = [{
      day,
      observation: [prevision.message.$],
      isMessage: true
    }]
  }

  return {
    updateTime,
    day,
    data,
    header
  }
}

export function extractTupleFromForecastHourlyFilteredByDate (data) {
  // first, we have to create a new tuple from all the data in the response
  // the data contained in the response are all the files received during the filter
  // but, we have to display the latest data for each day present in the response
  const knownDates = {}
  const hourlyData = []
  for (let i = 0; i < data.length; ++i) {
    const currentData = data[i]

    if (currentData.data.prevision &&
        currentData.data.prevision.section &&
        currentData.data.prevision.section.length > 1 &&
        currentData.data.prevision.section[1] &&
        currentData.data.prevision.section[1].jour &&
        currentData.data.prevision.section[1].jour.length > 0) {
      const currentPrevisionDateYMDString = currentData.data.prevision.section[1].jour[0]['@date'].toString().substring(0, 8)
      const currentValueDateYMDHMSString = currentData.data.prevision['@date'].toString()
      if ((!knownDates[currentPrevisionDateYMDString]) || // if the date doesn't exist
          (knownDates[currentPrevisionDateYMDString] &&
              knownDates[currentPrevisionDateYMDString].valueDate <= currentValueDateYMDHMSString
          ) // or if the date exists already, but is older than the current date
      ) {
        // push it to the hourlyData !
        knownDates[currentPrevisionDateYMDString] = {
          valueDate: currentValueDateYMDHMSString,
          index: i
        }
      }
    }
  }

  // then, build the tuple
  const knownDatesKeys = Object.keys(knownDates)
  for (let i = 0; i < knownDatesKeys.length; ++i) {
    const knownDate = knownDates[knownDatesKeys[i]]
    hourlyData.push(data[knownDate.index])
  }

  return hourlyData
}

export function convertRainCellsDirectionToOrientation (direction) {
  // first, adapt the direction to a specific value
  const sixteenRoseIteration = 22.5
  const directionIterationsRounded = Math.round(direction / sixteenRoseIteration)
  const currentDirectionInSixteenRose = Math.trunc(directionIterationsRounded * sixteenRoseIteration)
  const conversionTable = []
  conversionTable['0'] = 's'
  conversionTable['22'] = 'sso'
  conversionTable['45'] = 'so'
  conversionTable['67'] = 'oso'
  conversionTable['90'] = 'o'
  conversionTable['112'] = 'ono'
  conversionTable['135'] = 'no'
  conversionTable['157'] = 'nno'
  conversionTable['180'] = 'n'
  conversionTable['202'] = 'nne'
  conversionTable['225'] = 'ne'
  conversionTable['247'] = 'ene'
  conversionTable['270'] = 'e'
  conversionTable['292'] = 'ese'
  conversionTable['315'] = 'se'
  conversionTable['337'] = 'sse'
  conversionTable['360'] = 's'

  return conversionTable[currentDirectionInSixteenRose]
}

/**
 * Parse rain data
 *
 * @param {Prevision} prevision
 * @returns
 */
export function parseRain (prevision) {
  const date = DateTime.fromFormat(prevision['@date'].toString(), 'yyyyMMddHHmmss')
  const forecastRain = (prevision.section && prevision.section.length > 0)
    ? prevision.section[0]
    : null
  const time = DateTime.local().toFormat('T')
  // const updateTime = date.toFormat('T \'on\' EEE dd MMM') // 13:00 on Thu 19 Apr
  const updateTime = date.toFormat('T \'on\' EEE dd MMM') // 13:00 on Thu 19 Apr
  const day = date.toFormat('EEE MMM dd')

  const data = forecastRain
    ? {
      updateTime,
      day,
      time,
      type: forecastRain.rr_type.$,
      fewDrops: forecastRain.rr_few_drops ? forecastRain.rr_few_drops.$ : null,
      beginning: forecastRain.rr_deb ? forecastRain.rr_deb.$ : null,
      end: forecastRain.rr_fin ? forecastRain.rr_fin.$ : null,
      interm: forecastRain.rr_interm ? forecastRain.rr_interm.$ : null,
      intensity: forecastRain.rr_intens ? forecastRain.rr_intens.$.toString() : null,
      direction: forecastRain.rr_to_360 ? forecastRain.rr_to_360.$ : null,
      cardinalDirection: forecastRain.rr_to_360
        ? convertRainCellsDirectionToOrientation(forecastRain.rr_to_360.$)
        : null,
      speed: forecastRain.rr_speed ? forecastRain.rr_speed.$ : null,
      comment: forecastRain.rr_comment ? forecastRain.rr_comment.$ : null,
      // 0 if it's sunny (no beginning date, no ending date)
      // 1 if it's going to rain (beginning date)
      // 2 if it's raining (no beginning date, ending date present)
      // 3 if it's sunny but few drops are present (type = 0 & few_drops = 1)
      status: (
        forecastRain.rr_type.$ === 0 &&
          forecastRain.rr_few_drops &&
          forecastRain.rr_few_drops.$ === 1
          ? 3
          : forecastRain.rr_deb
            ? 1
            : (
              forecastRain.rr_fin
                ? 2
                : 0
            )
      ),
      isMessage: false
    }
    : {
      updateTime,
      day,
      time,
      isMessage: true,
      message: prevision.message.$
    }

  return data
}

/**
 * Parse all data received from forecast requests: daily, hourly and rain.
 * Also computes the global range of the received dates.
 * For each result, we add a luxonDate in two steps
 * - first step, we create a luxon ISO date
 * - second step, we transform it with a string format
 * we need to do these two steps, if we don't do this, we can't make a true diff later in the code
 * but I don't know why...
 * we do this to avoid create this luxonDate each time we'll reduce data for display purpose
 */
export function parseForecastData (data: any[], timezone: string, datetimeFormat = 'yyyyMMddHHmmss'): ForecastDataFromAPI {
  let rangeStartDatestring = null
  let rangeEndDatestring = null
  const availableRanges: D3TimelineSerie = {
    type: 'forecast',
    data: [],
    name: 'Forecast data'
  }

  /**
   * Local helper refreshing range start and range end dates.
   * Updates variables rangeStartDatestring & rangeEndLusonDate.
   */
  // function refreshRangeStartEndDates (luxonDate: DateTime) {
  function refreshRangeStartEndDates (startDatestring: number, endDatestring: number) {
    if (startDatestring && (rangeStartDatestring === null || rangeStartDatestring > startDatestring)) {
      rangeStartDatestring = startDatestring
    }

    if (endDatestring && (rangeEndDatestring === null || rangeEndDatestring < endDatestring)) {
      rangeEndDatestring = endDatestring
    }
  }

  const daily = [...data[0]]
  for (let index = 0; index < daily.length; index++) {
    const section = daily[index].data && daily[index].data.prevision && daily[index].data.prevision.section
    const lastHour = section && section[1].jour[section[1].jour.length - 1]['@date'] + '235959'
    const currentDateTime = DateTime.fromISO(daily[index].value_date, { setZone: true, zone: timezone }).toFormat(datetimeFormat)
    const luxonDate = DateTime.fromFormat(currentDateTime, datetimeFormat, { setZone: true, zone: timezone })
    daily[index].datetime = stringifyDate(luxonDate)
    availableRanges.data.push([daily[index].datetime, stringifyDate(luxonDate.plus({ minutes: 15 }))])
    refreshRangeStartEndDates(+daily[index].datetime, lastHour ? +lastHour : null)
  }

  const hourly = [...data[1]]
  for (let index = 0; index < hourly.length; index++) {
    const currentDateTime = DateTime.fromISO(hourly[index].value_date, { setZone: true, zone: timezone }).toFormat(datetimeFormat)
    const luxonDate = DateTime.fromFormat(currentDateTime, datetimeFormat, { setZone: true, zone: timezone })
    hourly[index].datetime = stringifyDate(luxonDate)
    availableRanges.data.push([hourly[index].datetime, stringifyDate(luxonDate.plus({ minutes: 15 }))])

    // we take only in consideration the last forecast hour
    // if (index === hourly.length - 1) {
    const section = hourly[index].data && hourly[index].data.prevision && hourly[index].data.prevision.section
    const lastHour = section && section[1].jour[section[1].jour.length - 1]['@date']
    refreshRangeStartEndDates(+hourly[index].datetime, +lastHour)
    // }
  }

  const rain = [...data[2]]
  for (let index = 0; index < rain.length; index++) {
    const currentDateTime = DateTime.fromISO(rain[index].value_date, { setZone: true, zone: timezone }).toFormat(datetimeFormat)
    const luxonDate = DateTime.fromFormat(currentDateTime, datetimeFormat, { setZone: true, zone: timezone })
    rain[index].datetime = stringifyDate(luxonDate)
    availableRanges.data.push([rain[index].datetime, stringifyDate(luxonDate.plus({ minutes: 15 }))])

    refreshRangeStartEndDates(+rain[index].datetime, +rain[index].datetime)
  }

  const range = [
    rangeStartDatestring ? rangeStartDatestring.toString() : null,
    rangeEndDatestring ? rangeEndDatestring.toString() : null
  ]
  // sort range of available data by time
  availableRanges.data.sort((ra, rb) => (ra[0] > rb[0] ? 1 : 0))

  return {
    daily,
    hourly,
    rain,
    range,
    availableRanges
  }
}

/**
 * Convert Date in DateTime if not (specially for tests env.)
 *
 * @export
 * @param {string|object} date DateTime luxon object of string in test env.
 */
export function getDate (date: any): DateTime {
  let dateTimeObj = date
  // Convert Date in DateTime if not (specially for tests env.)
  if (typeof date === 'string') {
    dateTimeObj = DateTime.fromISO(date)
  }
  return dateTimeObj
}

/**
 * Filter Forecast data by date range
 */
export function filterForecastDataByDate (
  data: ForecastDataFromAPI,
  referenceDate: DateTime,
  timezone: string,
  sessions: Array<WeatherEventSession>,
  config
): {
  hourly: ForecastDataHourly;
  daily: ForecastDataDaily;
  rain: ForecastData[];
} {
  // Filter daily data before analyzing.
  // Only keep past days. Do not compute any daily data if current referenceDate
  // is beyond any forecast for this event.
  let daily
  const referenceDateString = stringifyDate(referenceDate)
  if (data.range && data.range[1] && referenceDateString <= data.range[1]) {
    // Keep past referenceDates only (compared to passed in `referenceDate`).
    const dailyDataToAnalyze = data.daily.filter(currentItem =>
      // referenceDate.diff(getDate(currentItem.luxonDate), 'hours').toObject().hours >= 0
      (referenceDateString >= currentItem.datetime)
    ).sort((a, b) => (a.value_date < b.value_date ? 0 : 1))
    if (dailyDataToAnalyze.length || data.daily.length) {
      const prevision = dailyDataToAnalyze.length
        ? dailyDataToAnalyze[dailyDataToAnalyze.length - 1].data.prevision
        : data.daily[data.daily.length - 1].data.prevision
      daily = analyzeResponseForecastDaily(prevision, timezone, referenceDate, config)
    }
  }

  // filter hourly data before analyzing
  let hourly
  if (data.hourly?.length) {
    const hourlyDataToAnalyze = data.hourly.filter(currentItem =>
    // return referenceDate.diff(getDate(currentItem.luxonDate), 'hours').toObject().hours >= 0
      (referenceDateString >= currentItem.datetime)
    ).sort((a, b) => (a.value_date < b.value_date ? 0 : 1))
    if (hourlyDataToAnalyze.length) {
      hourly = analyzeResponseForecastHourly(
        extractTupleFromForecastHourlyFilteredByDate(hourlyDataToAnalyze),
        timezone,
        referenceDate,
        sessions,
        config
      )
    }
  }

  let rain
  if (data.rain?.length) {
    // filter rain data before analyzing
    const rainDataToAnalyze = data.rain.filter(currentItem =>
      (referenceDateString >= currentItem.datetime)
    ).sort((a, b) => (a.value_date < b.value_date ? 0 : 1))
    //   return referenceDate.diff(getDate(currentItem.luxonDate), 'hours').toObject().hours >= 0
    // })
    if (rainDataToAnalyze.length) {
      rain = parseRain(rainDataToAnalyze[rainDataToAnalyze.length - 1].data.prevision)
    }
  }
  return {
    daily,
    hourly,
    rain
  }
}

/**
 * Find the latest forecast data for a specific type
 */
export function findLatestForecastValueDateForSpecificType (
  data: ForecastDataFromAPI,
  type: 'forecast' | 'rain'
): string | null {
  switch (type) {
    case 'forecast':
      if (data.hourly.length > 0) {
        const latestHourlyData = data.hourly.sort((a, b) => a.datetime > b.datetime ? 1 : -1)
        return latestHourlyData[latestHourlyData.length - 1].datetime
      } else if (data.daily.length > 0) {
        const latestDailyData = data.daily.sort((a, b) => a.datetime > b.datetime ? 1 : -1)
        return latestDailyData[latestDailyData.length - 1].datetime
      }
      break
    case 'rain':
      const latestRainData = data.rain.sort((a, b) => a.datetime > b.datetime ? 1 : -1)
      return latestRainData[latestRainData.length - 1].datetime
      break
  }
  return null
}
