/* eslint-disable no-console */
import {
  brushX,
  scaleTime,
  axisBottom,
  timeDay,
  timeHour,
  timeFormat,
  timeParse,
  ScaleTime,
  zoom
} from 'd3'

import { select } from 'd3-selection'

import { TimelineEvent, Mode, PeriodOfInterest, MAXZOOM_NB_DAYS } from './definitions'

const timeFormatter = timeFormat('%Y%m%d%H%M00')
const timeParser = timeParse('%Y%m%d%H%M%S')
const tipTimeFormatter = timeFormat('%H:%M')

interface EventsListeners {
  [TimelineEvent.onRangeChange]: Function;
  [TimelineEvent.onTimeChange]: Function;
  [TimelineEvent.onClickOutsidePast]: Function;
  [TimelineEvent.onClickOutsideFuture]: Function;
}

export class D3TimelineSerie {
  name: string;
  type: 'forecast' | 'radar' | 'observation';
  data: [string, string][];
}

export class D3Timeline {
  _debug = false
  /**
   * Container of the D3 Timeline
   */
  _container: HTMLElement

  _containerRange: any
  _containerSingle: any
  _containerOutsidePast: any
  _containerOutsideFuture: any

  _containerPOIs: any

  /**
   * Container width
   */
  get _width (): number {
    return this._container?.offsetWidth
  }

  /**
   * Container height
   */
  get _height (): number {
    return this._container?.offsetHeight - (this._size === 'normal' ? 16 : 0)
  }

  get _heightTicksHours (): number {
    return this._height - 8
  }

  get _heightTicksSixHours (): number {
    return this._height - 16
  }

  /**
   * Timeline size
   */
  _size: 'normal' | 'mini' = 'normal'

  set size (newValue: 'normal' | 'mini') {
    this._debug && console.trace('[timeline] size update')
    this._size = newValue
    /**
     * We don't rebuild the timeline,
     * as there is a resize event that is going to be run
     * after the Timeline.vue container has been resized.
     */
    // this.buildTimeline()
  }

  /**
   * Date range,
   */
  _dateRange: [string, string] = undefined

  /**
   * Setter for dateRange
   * * init the timeline,
   * * init the rangebrush,
   * * init single time brush
   */
  set dateRange (newValue: [string, string]) {
    this._debug && console.trace('[timeline] dateRange update')
    if (newValue.join('-') === this._dateRange?.join('-')) return
    this._dateRange = newValue
    this.buildTimeline()
    // this.zoomToActualLevel()
    // this.updateTimelineData()
  }

  get dateRange () {
    return this._dateRange
  }

  /**
   * Timeline mode, Range or Single
   */
  _mode: Mode = Mode.Range

  set mode (newValue: Mode) {
    this._mode = newValue
    this.updateTimelineData()
  }

  get mode () {
    return this._mode
  }

  /**
   * D3 Object of the Timeline `g` SVG Element
   */
  _timeline: any
  _timelineZoom: any
  _timelineActiveArea: any

  /**
   * D3 Scale of the original date range (_dateRange prop)
   */
  _xScale: ScaleTime<number, number>
  /**
   * D3 Scale of the data date range (_dataRange prop)
   */
  _xScaleData: ScaleTime<number, number>
  /**
   * D3 Scale of the actual zoom level
   */
  _xScaleZoom: ScaleTime<number, number>

  /**
   * Brush for the forecast,
   * selecting the time to visualize
   */
  _gSingle: any

  _dataRange: Array<number>

  /**
   * Group for the brush data / obs° period,
   * selecting the period to visualize
   */
  _gRange: any

  _rangeSelectedTime: any
  _rangeBrush: any
  _rangeHandlers: any
  /**
   * Brush for data / obs° period
   */
  _brushRange: any
  _brushCurrentTime: any

  _rangeTips: any
  _handle: any
  _selection: any
  _eventsListeners: EventsListeners = {
    [TimelineEvent.onRangeChange]: undefined,
    [TimelineEvent.onTimeChange]: undefined,
    [TimelineEvent.onClickOutsidePast]: undefined,
    [TimelineEvent.onClickOutsideFuture]: undefined
  }

  /**
   * Resizer utils
   */
  _resizer: any
  _resizeTimeout: number

  _selectedRange: [string, string]
  set selectedRange (newValue: [string, string]) {
    this._debug && console.log('[timeline] set selectedRange', newValue, this._selectedRange, this._selectedRange === newValue)
    if (this._selectedRange[0] === newValue[0] && this._selectedRange[1] === newValue[1]) return
    this._selectedRange = newValue
    this.updateTimelineData()
  }

  get selectedRange () {
    return this._selectedRange
  }

  _singleTip: any
  _handleCurrent: any

  /**
   * Current time is used for both Single and Range mode.
   *
   * This would need an update to be easier to use, but for now,
   * please understand that,
   * * in Range mode, you need to pass a currentTime that is the time of the radar image
   * * in Single mode, you need to pass the referenceDate
   */
  _currentTime: string
  set currentTime (newValue: string) {
    this._debug && console.log('[timeline] set currentTime', newValue, this._currentTime, this._currentTime === newValue)
    if (this._currentTime === newValue) return
    this._currentTime = newValue
    switch (this._mode) {
      case Mode.Range:
        this.setRangeCurrentTime()
        break
      case Mode.Single:
        this.setSingleCurrentTime()
        break
    }
  }

  get currentTime () {
    return this._currentTime
  }

  _maximumTimeAvailable: string
  set maximumTimeAvailable (newValue: string) {
    this._debug && console.log('[timeline] set maximumTimeAvailable', newValue, this._maximumTimeAvailable, this._maximumTimeAvailable === newValue, this._dateRange, newValue > this._dateRange[1])
    if (this._maximumTimeAvailable === newValue) return
    if (newValue > this._dateRange[1]) return
    this._maximumTimeAvailable = newValue
    this.updateAvailableTimedataRanges()
  }

  get maximumTimeAvailable () {
    return this._maximumTimeAvailable
  }

  _currentTimeCoords: any

  _availableDataTimeRanges: D3TimelineSerie[] = []

  set availableDataTimeRanges (newValue: D3TimelineSerie[]) {
    this._availableDataTimeRanges = newValue
    this.updateAvailableTimedataRanges()
  }

  get availableDataTimeRanges () {
    return this._availableDataTimeRanges
  }

  _currentTimeDisplay: string

  /**
   * Indicate if we are in live
   */
  _isLive: boolean

  set isLive (newValue: boolean) {
    this._isLive = newValue
    this.updateTimelineData()
  }

  get isLive () {
    return this._isLive
  }

  /**
   * Indicate if we have to show a green area
   * in the future to allow user click and go live
   * in Range / Single mode available for go live
   */
  _isActive: boolean

  set isActive (newValue: boolean) {
    this._isActive = newValue
    this.updateTextOutsideFuture()
  }

  get isActive () {
    return this._isActive
  }

  /**
   * Point (Period) Of Interest
   */
  _pois: [PeriodOfInterest]

  set pois (newValue: [PeriodOfInterest]) {
    this._debug && console.log('[timeline] set pois', newValue, this._pois)
    this._pois = newValue
    this.updatePOIs()
  }

  get pois () {
    return this._pois
  }

  get minZoom () {
    return 1
  }

  /**
   * Actual zoom level
   */
  _actualZoomLevel: number = null;

  get zoom () {
    return this._actualZoomLevel
  }

  set zoom (newValue) {
    if (newValue === this._actualZoomLevel) return
    if (!newValue) {
      const time0: Date = timeParser(this._dateRange[0])
      const time1: Date = timeParser(this._dateRange[1])
      const duration = Math.round((time1.valueOf() - time0.valueOf()) / 1000 / 60 / 60 / 24)
      this._actualZoomLevel = duration / 7
    } else {
      this._actualZoomLevel = newValue
    }
    // console.trace('[timeline] set zoom', this._actualZoomLevel, newValue)
    this.zoomToActualLevel()
  }

  get maxZoom () {
    const time0: Date = timeParser(this._dateRange[0])
    const time1: Date = timeParser(this._dateRange[1])
    const duration = Math.round((time1.valueOf() - time0.valueOf()) / 1000 / 60 / 60 / 24)
    return duration / MAXZOOM_NB_DAYS
  }

  get numberOfDaysDisplayed () {
    const firstTimeOfRange = this._xScaleZoom.invert(0)
    const lastTimeOfRange = this._xScaleZoom.invert(this._width)
    return Math.round((lastTimeOfRange.valueOf() - firstTimeOfRange.valueOf()) / 1000 / 60 / 60 / 24)
  }

  get _ticksHoursEvery () {
    const n = this.numberOfDaysDisplayed
    if (n <= 8) {
      return 2
    } else {
      return 4
    }
  }

  get _ticks6HoursEvery () {
    const n = this.numberOfDaysDisplayed
    if (n <= 8) {
      return 6
    } else {
      return 12
    }
  }

  /**
   *
   * @param container
   * HTMLElement containing the D3 Timeline
   *
   * @param dateRange
   * Range of date defining the min / max of the D3 Timeline
   * Must be in the %Y%m%d%H%M%S format
   *
   * @param mode
   * Range or Single,
   * define how the user will interact with the D3 Timeline
   *
   * @param selectedRange
   * Range selected by the user
   */
  constructor (
    container: HTMLElement,
    dateRange: [string, string],
    mode: Mode = Mode.Range,
    // availableTimeRanges: [string, string][] = [],
    availableTimeRanges: D3TimelineSerie[] = [],
    currentTime: string,
    // currentRangeTime?:string,
    maximumTimeAvailable?: string,
    selectedRange?: [string, string],
    isLive?: boolean,
    isActive?: boolean,
    size: 'normal' | 'mini' = 'normal',
    pois?: [PeriodOfInterest],
    zoom?: number
  ) {
    this._container = container
    this._mode = mode
    this._dateRange = dateRange
    this._size = size

    if (zoom) {
      this._actualZoomLevel = zoom
    } else {
      const time0: Date = timeParser(this._dateRange[0])
      const time1: Date = timeParser(this._dateRange[1])
      const duration = Math.round((time1.valueOf() - time0.valueOf()) / 1000 / 60 / 60 / 24)
      this._actualZoomLevel = duration / 7
    }

    // Listen to resize event to regenerate timeline on container size change
    window.addEventListener('resize', this.onResize)
    this._resizer = new ResizeObserver(() => {
      this._resizeTimeout = setTimeout(() => {
        this.onResize()
      }, 100)
    })
    this._resizer.observe(container)

    this._availableDataTimeRanges = availableTimeRanges || []

    this._currentTime = currentTime || dateRange[0]
    // currentTimeRadar && (this._currentTimeRadar = currentTimeRadar)
    maximumTimeAvailable && (this._maximumTimeAvailable = maximumTimeAvailable)
    selectedRange && (this._selectedRange = selectedRange)
    isLive && (this._isLive = isLive)
    isActive && (this._isActive = isActive)
    pois && (this._pois = pois)
    this.buildTimeline()
    // this.updateTimelineData()
    /**
     * We'll trigger zoom 300ms after construction,
     * because we still can receive data from the front (like currentTime)
     */
    setTimeout(() => {
      this._debug && console.log('[timeline] constructor, going to zoom to actual level')
      this.zoomToActualLevel(true)
    }, 300)
  }

  /**
   * Build all the g elements for the D3 Timeline
   * * the svg itself (the timeline)
   * * the
   *
   * @memberof Timeline
   */
  buildTimeline () {
    this._debug && console.log('[timeline] buildTimeline', this._dateRange[0], this._dateRange[1])
    select(this._container).select('svg').remove()

    this._xScale = scaleTime()
      .domain([
        timeParser(this._dateRange[0]),
        timeParser(this._dateRange[1])
      ])
      .rangeRound([0, this._width])
    this._debug && console.log('[timeline] buildTimeline _xScaleZoom')
    this._xScaleZoom = this._xScale

    this._timelineZoom = zoom()
      .scaleExtent([this.minZoom, this.maxZoom])
      .extent([[0, 0], [this._width, this._height]])
      .translateExtent([[0, 0], [this._width, this._height]])
      // .translateExtent([[0, 0], [this._width, Infinity]])
      .on('zoom', this.onZoom)
      .on('end', this.onZoomEnd)

    this._timeline = select(this._container).append('svg')
      .attr('class', 'timeline')
      .attr('width', this._width)
      .attr('height', this._height)
      .style('pointer-events', 'all')
      .call(this._timelineZoom)

    const svgDefs = this._timeline.append('defs')

    const gradientLeftToRight = svgDefs.append('linearGradient')
      .attr('id', 'left-to-right')
    gradientLeftToRight.append('stop')
      .attr('class', 'svg-color-background')
      .attr('offset', '0')
    gradientLeftToRight.append('stop')
      .attr('class', 'svg-color-transparent')
      .attr('offset', '1')

    const gradientRightToLeft = svgDefs.append('linearGradient')
      .attr('id', 'right-to-left')
    gradientRightToLeft.append('stop')
      .attr('class', 'svg-color-transparent')
      .attr('offset', '0')
    gradientRightToLeft.append('stop')
      .attr('class', 'svg-color-background')
      .attr('offset', '1')

    this._timelineActiveArea = this._timeline.append('g')
      .attr('class', 'axis axis--grid timeline-active-area')
      .attr('height', this._height)
      .attr('width', this._width)

    this._timeline.append('g')
      .attr('class', 'axis axis--grid hour-ticks-six')
      .attr('x', 0)
      .attr('y', 0)
      .style('pointer-events', 'none')
      .attr('transform', `translate(0, ${this._heightTicksSixHours})`)

    this._timeline.append('g')
      .attr('class', 'axis axis--grid hour-ticks')
      .attr('x', 0)
      .attr('y', 0)
      .style('pointer-events', 'none')
      .attr('transform', `translate(0, ${this._heightTicksHours})`)

    this._containerRange = this._timeline.append('g')
      .attr('class', 'axis timeline-available-timedata-ranges')

    this._containerPOIs = this._timeline.append('g')
      .attr('class', 'axis axis--pois')

    this._containerSingle = this._timeline.append('g')
      .attr('class', 'axis timeline-single')

    this._timeline.append('g')
      .attr('class', 'axis axis--x')
      .style('pointer-events', 'none')
      .attr('transform', 'translate(0, 8)')

    /** Add svg shapes on forbidden area */
    this._containerOutsidePast = this._timeline.append('g')
      .attr('class', 'area--data-outside-past')
      .attr('cursor', 'pointer')
      .on('click', () => this.trigger(TimelineEvent.onClickOutsidePast))

    this._containerOutsidePast.append('title')
      .text('No available data on this period')

    /** Add svg shapes on forbidden area */
    this._containerOutsideFuture = this._timeline.append('g')
      .attr('class', 'area--data-outside-future')
      .attr('cursor', 'pointer')
      .on('click', () => this.trigger(TimelineEvent.onClickOutsideFuture))

    this._containerOutsideFuture
      .append('title')
      .text(this._isActive ? 'Click here to go live !' : 'No available data on this period')

    this._containerOutsideFuture.append('text')

    this._timeline.append('g')
      .attr('class', 'area--before-displayed-timeline')
      .append('rect')
      .attr('class', 'gradient-left-right')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100px')
      .attr('height', this._height)

    this._timeline.append('g')
      .attr('class', 'area--after-displayed-timeline')
      .append('rect')
      .attr('class', 'gradient-right-left')
      .attr('x', (this._width - 100) + 'px')
      .attr('y', 0)
      .attr('width', '100px')
      .attr('height', this._height)
  }

  /**
   * Update all data displayed by the timeline
   * Use the 'current zoom scale'
   * * ticks every x hours depending on the scale zoom
   * * ticks every days
   * * days displayed
   */
  updateTimelineData () {
    this._debug && console.log('[timeline] updateTimelineData', this._xScaleZoom, this._dataRange)
    // area containing the "active"
    this._timelineActiveArea
      .call(
        axisBottom(this._xScaleZoom)
          .ticks(timeDay)
          .tickSize(this._height)
          .tickFormat(function () { return null })
          .tickPadding(0)
      )

    // Ticks every 4 hours at the bottom
    this._timeline.selectAll('.axis.axis--grid.hour-ticks')
      .call(
        axisBottom(this._xScaleZoom)
          .ticks(timeHour.every(this._ticksHoursEvery)) // TICK_TIMELINE_INTERVAL
          .tickSize(8)
          .tickFormat(function () { return null })
      )
      .attr('transform', `translate(0, ${this._heightTicksHours})`)

    // Ticks every 4 hours at the bottom
    this._timeline.selectAll('.axis.axis--grid.hour-ticks-six')
      .call(
        axisBottom(this._xScaleZoom)
          .ticks(timeHour.every(this._ticks6HoursEvery)) // TICK_TIMELINE_INTERVAL
          .tickSize(16)
          .tickFormat(function () { return null })
      )
      .attr('transform', `translate(0, ${this._heightTicksSixHours})`)

    // Day labels
    this._timeline.selectAll('.axis.axis--x')
      .call(
        axisBottom(this._xScaleZoom)
          .ticks(timeDay)
          .tickFormat(timeFormat('%a %d'))
          .tickPadding(0)
          .tickSize(0)
      )
      .attr('text-anchor', null)
      .selectAll('text')
      .attr('x', 3)

    const xBegin = this._xScaleZoom(timeParser(this._dateRange[0]))
    const xEnd = this._xScaleZoom(timeParser(this._dateRange[1]))
    this._timeline.select('.area--after-displayed-timeline')
      .attr('class', 'area--after-displayed-timeline ' + (
        xEnd === this._width ? 'hidden' : ''
      ))
      .select('rect')
      .attr('x', (this._width - 100) + 'px')
    this._timeline.select('.area--before-displayed-timeline')
      .attr('class', 'area--before-displayed-timeline ' + (
        xBegin === 0 ? 'hidden' : ''
      ))

    this.updateAvailableTimedataRanges()
    this.updateSelectionRangeBrush()
    this.updateSingleTimeBrush()
    this.updatePOIs()
  }

  /**
   * Modifiers handlers
   */

  /**
   * Make a zoom in from the current period / time
   */
  zoomIn () {
    this._debug && console.log('[timeline] zoomIn', this._timelineZoom)
    let x
    switch (this._mode) {
      case Mode.Range:
        x = this._xScaleZoom(timeParser(this._selectedRange[1]))
        break
      case Mode.Single:
        x = this._xScaleZoom(timeParser(this._currentTime))
        break
    }
    if (x >= 0 && x <= this._width) {
      this._timeline
        .transition()
        .duration(750)
        .call(this._timelineZoom.scaleBy, 1.5, [x, -1])
    } else {
      this._timeline
        .transition()
        .duration(750)
        .call(this._timelineZoom.scaleBy, 1.5)
    }
  }

  /**
   * Make a zoom out from the current period / time
   */
  zoomOut () {
    this._debug && console.log('[timeline] zoomOut', this._timelineZoom)
    let x
    switch (this._mode) {
      case Mode.Range:
        x = this._xScaleZoom(timeParser(this._selectedRange[1]))
        break
      case Mode.Single:
        x = this._xScaleZoom(timeParser(this._currentTime))
        break
    }
    if (x >= 0 && x <= this._width) {
      this._timeline
        .transition()
        .duration(750)
        .call(this._timelineZoom.scaleBy, 0.75, [x, -1])
    } else {
      this._timeline
        .transition()
        .duration(750)
        .call(this._timelineZoom.scaleBy, 0.75)
    }
  }

  /**
   * Center the timeline zoom to the current period / time
   * with the default zoom level
   */
  center () {
    this._debug && console.log('[timeline] center', this._timelineZoom)
    let x
    switch (this._mode) {
      case Mode.Range:
        x = this._xScale(timeParser(this._selectedRange[1]))
        break
      case Mode.Single:
        x = this._xScale(timeParser(this._currentTime))
        break
    }
    this._timeline
      .transition()
      .duration(750)
      .call(this._timelineZoom.translateTo, x, -1)
  }

  /**
   * Reset the timeline zoom to the event period
   */
  resetZoom () {
    this._debug && console.log('[timeline] resetZoom', this._timelineZoom)
    this._timeline
      .transition()
      .duration(750)
      .call(this._timelineZoom.scaleTo, 1)
  }

  zoomToActualLevel (withTransition?: boolean) {
    this._debug && console.log('[timeline] zoomToActualLevel', this._timelineZoom, this._actualZoomLevel)
    let x
    switch (this._mode) {
      case Mode.Range:
        if (!this._selectedRange) return
        x = this._xScale(timeParser(this._selectedRange[1]))
        break
      case Mode.Single:
        if (!this._currentTime) return
        x = this._xScale(timeParser(this._currentTime))
        break
    }
    this._debug && console.log('[timeline] zoomToActualLevel (x, width)', x, this._width)
    if (x > this._width) x = this._width
    if (x < 0 || !x) x = 0
    this._debug && console.log('[timeline] zoomToActualLevel to x: ', x)
    if (withTransition) {
      this._timeline
        .transition()
        .duration(2000)
        .call(this._timelineZoom.scaleTo, this._actualZoomLevel, [x, -1])
    } else {
      this._timeline.call(this._timelineZoom.scaleTo, this._actualZoomLevel, [x, -1])
    }
  }

  /**
   * Events handlers
   */

  /**
   * Attach a listener to an event triggered by the timeline
   *
   * @param event Event to attach the listener
   * @param listener Function that will be called when the event will be trigerred
   */
  on (event: TimelineEvent, listener: Function) {
    this._eventsListeners[event] = listener
  }

  /**
   * Remove a listener to an event triggered by the timeline
   *
   * @param event Event to remove the listener
   */
  off (event: TimelineEvent) {
    this._eventsListeners[event] = undefined
  }

  /**
   * Trigger the listener attached to an event if it exists
   *
   * @param event Event to be triggered
   */
  trigger (event: TimelineEvent, ...payload: any) {
    const listener = this._eventsListeners[event]
    if (!listener) return
    listener(...payload)
  }

  /**
   * Remove listeners when timeline need to be destroyed
   */
  destroy () {
    window.removeEventListener('resize', this.onResize)
    this._resizer && this._resizer.unobserve(this._container)
    this._resizeTimeout && clearTimeout(this._resizeTimeout)
  }

  /**
   * Actions to be done when a resize event is triggered by the browser
   */
  onResize = () => {
    // Reset default elements
    // this._gRange = undefined
    // this._gSingle = undefined
    this._debug && console.log('[timeline] onResize', this._timelineZoom)
    select(this._container).select('svg')
      .attr('width', this._width)
      .attr('height', this._height)
    this._timeline
      .attr('width', this._width)
      .attr('height', this._height)
    this._timelineActiveArea
      .attr('width', this._width)
      .attr('height', this._height)
    this._xScale = this._xScale.rangeRound([0, this._width])
    this._debug && console.log('[timeline] onResize _xScaleZoom', this._width)
    this._xScaleZoom = this._xScaleZoom.rangeRound([0, this._width])

    this._timelineZoom
      .extent([[0, 0], [this._width, this._height]])
      .translateExtent([[0, -Infinity], [this._width, Infinity]])

    this.updateTimelineData()
  }

  /**
   * On d3 zoom, update the xScale
   * then rebuild the timeline
   */
  onZoom = (event) => {
    this._debug && console.log('[timeline] onZoom', this._timelineZoom, this._actualZoomLevel, event.transform.k, this._actualZoomLevel === event.transform.k, event.transform)
    if (!event.transform.k) return
    this._actualZoomLevel = event.transform.k
    this._debug && console.log('[timeline] onZoom _xScaleZoom')
    this._xScaleZoom = event.transform.rescaleX(this._xScale)
    this.updateTimelineData()
  }

  onZoomEnd = (event) => {
    this._debug && console.log('[timeline] onZoomEnd', event.transform)
    this._actualZoomLevel = event.transform.k
    this.trigger(
      TimelineEvent.onZoomChange,
      this._actualZoomLevel,
      this._actualZoomLevel === this.minZoom,
      this._actualZoomLevel === this.maxZoom
    )
  }

  /**
   * Mode.Range methods
   */

  /**
   * Add ranges on the timeline
   * showing where radar data is available.
   *
   * @memberof Timeline
   */
  updateAvailableTimedataRanges = () => {
    this._debug && console.log('[timeline] updateAvailableTimedataRanges', this._dataRange)
    // Remove existing available ranges if any.
    this._containerRange.selectAll('.available-timedata-ranges').remove()

    // Retrieve ranges boundaries and initialize the radar range with them.
    let firstDatestring = null
    let lastDatestring = null
    this._availableDataTimeRanges.forEach(currentDataTimeRange => {
      if (currentDataTimeRange.data.length > 0) {
        const currentFirstDatestring = currentDataTimeRange?.data?.[0]?.[0]
        const currentLastDatestring = currentDataTimeRange?.data?.[currentDataTimeRange?.data?.length - 1]?.[1]
        if (!firstDatestring || currentFirstDatestring < firstDatestring) firstDatestring = currentFirstDatestring
        if (!lastDatestring || currentLastDatestring > lastDatestring) lastDatestring = currentLastDatestring
        this._debug && console.log('[timeline] updateAvailableTimedataRanges for type', currentDataTimeRange.type, currentFirstDatestring, currentLastDatestring, firstDatestring, lastDatestring)
      }
    })
    firstDatestring = firstDatestring || this._dateRange[0]
    // use of maximumTimeAvailable if it's > at lastDatestring
    lastDatestring = lastDatestring || this._dateRange[1]

    if (this._isActive && this._dateRange[1] > this._maximumTimeAvailable) {
      lastDatestring = this._maximumTimeAvailable || lastDatestring || this._dateRange[1]
    }
    this._debug && console.log('[timeline] updateAvailableTimedataRanges', firstDatestring, lastDatestring)
    this.setAvailableRangeMinMax(firstDatestring, lastDatestring)

    if (this._availableDataTimeRanges?.length === 0) return

    this._availableDataTimeRanges.forEach(({
      data, name, type
    }) => {
      if (data?.length > 0) {
        // Create rectangles representing ranges of available data on the X axis.
        for (let i = 0; i < data.length; i++) {
          const range = data[i]

          const startX = this._xScaleZoom(timeParser(range[0]))
          const endX = this._xScaleZoom(timeParser(range[1]))
          const width = endX - startX
          let rangeHeight = 8
          let y = 0
          switch (type) {
            case 'forecast':
              y = 0
              rangeHeight = 4
              break
            case 'observation':
              y = this._height - rangeHeight
              break
            case 'radar':
              rangeHeight = 6
              y = this._height - rangeHeight
              break
            default:
          }

          this._containerRange.append('rect')
            .attr('class', `available-timedata-ranges ${type}`)
            .attr('x', startX)
            .attr('y', y)
            .attr('width', width < 0 ? 0 : width)
            .attr('height', rangeHeight)
            .append('title')
            .text(name)
        }
      }
    })
  }

  /**
   * Add grey area before first and after last data image
   * and constrain brush range inside this range.
   */
  setAvailableRangeMinMax = (minDate: string, maxDate: string) => {
    this._debug && console.log('[timeline] setAvailableRangeMinMax', minDate, maxDate)
    this.updateOutsideAvailableRange(minDate, maxDate)

    this._xScaleData = scaleTime()
      .domain([
        timeParser(minDate),
        timeParser(maxDate)
      ])
      .rangeRound(this._dataRange)

    // Reset brush range on new range
    this.updateSelectionRangeBrush()
    this.updateSingleTimeBrush()
  }

  /**
   * Adds greyed out areas before avec after active range, so users clearly
   * see they are out of reach.
   * @param {string} minDate: Start of available range, formatted as a datestring "yyyyMMddHHmmss".
   * @param {string} maxDate: End of available range, formatted as a datestring "yyyyMMddHHmmss".
   * @memberof Timeline
   */
  updateOutsideAvailableRange = (minDate: string, maxDate: string) => {
    this._dataRange = [
      this._xScaleZoom(timeParser(minDate)),
      this._xScaleZoom(timeParser(maxDate))
    ]

    // Before area
    const rectOustidePast = this._containerOutsidePast.select('rect')
    if (rectOustidePast.empty()) {
      this._containerOutsidePast.append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', this._dataRange[0] < 0 ? 0 : this._dataRange[0])
        .attr('height', this._height)
    } else {
      rectOustidePast.attr('width', this._dataRange[0] < 0 ? 0 : this._dataRange[0])
    }
    // After area
    const rectOustideFuture = this._containerOutsideFuture.select('rect')
    if (rectOustideFuture.empty()) {
      this._containerOutsideFuture.append('rect')
        .attr('x', this._dataRange[1])
        .attr('y', 0)
        .attr('width', this._width - this._dataRange[1] < 0 ? 0 : this._width - this._dataRange[1])
        .attr('height', this._height)
    } else {
      rectOustideFuture
        .attr('x', this._dataRange[1])
        .attr('width', this._width - this._dataRange[1] < 0 ? 0 : this._width - this._dataRange[1])
    }
    this.updateTextOutsideFuture()
  }

  updateTextOutsideFuture () {
    const textOustideFuture = this._containerOutsideFuture.select('text')
    if (textOustideFuture.empty()) {
      this._containerOutsideFuture
        .append('text')
        .attr('y', this._dataRange[1] + 15)
        .attr('x', -this._height + 10)
        .text(this._isActive ? 'LIVE' : '')
    } else {
      textOustideFuture.attr('y', this._dataRange[1] + 15)
        .attr('x', -this._height + 10)
        .text(this._isActive ? 'LIVE' : '')
    }
  }

  /**
   * Handle on brush range start, move and end
   *
   * @memberof Timeline
   */
  onSelectionRangeMove (event) {
    // Hide handlers and rangeTips if no range selection
    if (
      event.selection === null ||
      typeof event.selection === 'undefined'
    ) {
      this._handle.attr('display', 'none')
      this._rangeTips.attr('display', 'none')
      return
    }
    const newRangeDates = event.selection.map(this._xScaleData.invert)
    const newRangeDatestrings = [
      timeFormatter(newRangeDates[0]),
      timeFormatter(newRangeDates[1])
    ]

    if (event.type === 'end') {
      // Early returns if no event selected
      if (
        event.sourceEvent === null ||
        typeof event.sourceEvent === 'undefined'
      ) {
        // if a previous selection is known, reset it
        if (
          this._selection &&
          JSON.stringify(this._selection) !== JSON.stringify(event.selection)
        ) {
          select('.brush').transition().call(event.target.move, this._selection)
        }

        // Ensure tooltips keep displaying current date when no brushing occured from user.
        this._rangeTips.text((d, i) => {
          if (this._isLive && i === 1) {
            return 'LIVE'
          } else if (this._selectedRange[i]) {
            return tipTimeFormatter(
              timeParser(this._selectedRange[i])
            )
          }
          return null
        })

        return
      }

      this._selection = event.selection
      select('.brush').transition().call(
        event.target.move,
        newRangeDates.map(this._xScaleData)
      )
      if (
        this._isActive &&
        parseInt(this._maximumTimeAvailable) - parseInt(newRangeDatestrings[1]) <= 300
      ) {
        this.trigger(TimelineEvent.onClickOutsideFuture)
        this.trigger(TimelineEvent.onRangeChange, newRangeDatestrings, true)
      } else {
        this.trigger(TimelineEvent.onRangeChange, newRangeDatestrings)
      }
    }

    // Update rangeTips values
    this._rangeTips.attr('display', null)
      .attr('transform', (d, i) => (`translate(${event.selection[i]}, -2)`))
      .attr('text-anchor', (d, i) => {
      // If 2 handlers are too closed, set position to start and end
        if (event.selection[1] - event.selection[0] < 40) {
          return `${i ? 'start' : 'end'}`
        }
        // Either, center rangeTips
        return 'middle'
      })
    // While dragging, update the tooltips.
      .text((d, i) => tipTimeFormatter(newRangeDates[i]))

    // Update handlers position
    this._handle
      .attr('display', null)
      .attr('transform', (d, i) => {
        return `translate(
          ${i
            ? +event.selection[i]
            : +event.selection[i]
          },
          ${this._height / 2}
        )
        `
      })
  }

  /**
   * Initialize brush for range date selection
   * Depending on mode, remove or set brush
   *
   * @memberof Timeline
   */
  updateSelectionRangeBrush () {
    this._debug && console.trace('[timeline] updateSelectionRangeBrush', this._dataRange, this._selectedRange)
    // Remove any existing selection brush.
    if (
      this._timeline.select('.brush-range') &&
      !this._timeline.select('.brush-range').empty()
    ) {
      this._gRange.call(this._brushRange.move, null)
      this._timeline.select('.brush-range').remove()
    }

    if (!this._selectedRange || !this._xScaleData) {
      return
    }

    this._debug && console.log('[timeline] updateSelectionRangeBrush checking mode')
    // If mode isn't 'range', hide brush
    if (this._mode !== Mode.Range) {
      return
    }

    const selectedRangeCoords = this._selectedRange.map(timeParser).map(this._xScaleData)
    if (
      this._timeline.select('.brush-range') &&
      !this._timeline.select('.brush-range').empty()
    ) {
      this._gRange.call(this._brushRange.move, selectedRangeCoords)
      return
    }
    // Else, create brush
    // Paint brush (selection)
    this._brushRange = brushX()
      .extent([[this._dataRange[0], 0], [this._dataRange[1], this._height]])
      .on('start brush end', e => {
        if (e.sourceEvent) {
          e.sourceEvent.stopImmediatePropagation()
        }
        this.onSelectionRangeMove(e)
      })

    this._gRange = this._timeline.append('g')
      .attr('class', 'brush-range')
      .call(this._brushRange)

    // Selection handlers
    this._handle = this._gRange.selectAll('.handle--custom')
      .data([{ type: 'w' }, { type: 'e' }])
      .enter().append('g')
      .attr('class', 'handle-group')
      .attr('cursor', 'ew-resize')
      .attr('transform', (d, i) => {
        return `
        translate(
          ${i ? +selectedRangeCoords[i] : +selectedRangeCoords[i]},
          ${this._height / 2}
        )`
      })

    /**
     * Add range tooltips for brush handles
     */
    this._gRange.selectAll('.handle--custom')
      .data([{ type: 'w' }, { type: 'e' }])
      .enter().append('g')
      .attr('class', 'rangeTips')

    this._rangeTips = this._gRange.selectAll('.rangeTips')
      .append('text')
      .attr('transform', `translate(12, ${this._height / 2 + 4})`)
      .text('')
      .attr('cursor', 'ew-resize')

    // Create handlers SVG
    this._gRange.selectAll('.handle-group')
      .append('polyline')
      .attr('class', 'handle--custom')
      .attr('fill-opacity', 0.6)
      .attr('stroke-width', 1.5)
      .attr('points', `
          1 0,
          1 -${this._height / 2},
          -1 -${this._height / 2},
          -1 -8,
          -5 -8,
          -5 8,
          -1 8,
          -1 ${this._height / 2},
          1 ${this._height / 2},
          1 0
        `)
      .attr('transform', (d, i) => (`rotate(${i ? '0' : '180'})`))

    // transparent rect to increase draggable area
    this._gRange.selectAll('.handle-group')
      .append('rect')
      .attr('fill-opacity', 0)
      .attr('x', -6)
      .attr('y', -this._height / 2)
      .attr('width', 20)
      .attr('height', this._height)
      .attr('transform', (d, i) => (`rotate(${i ? '0' : '180'})`))

    // Add range on _selectedRange from TimesProvider
    if (this._selectedRange) {
      this._gRange.call(this._brushRange.move, selectedRangeCoords)
    }

    // Remove overlay to allow use of range + currentTime brushes together
    this._gRange.selectAll('.brush-range>.overlay').remove()

    // Set current playing time position
    if (this._currentTime) {
      this._debug && console.log('[timeline] updateSelectionRangeBrush Trigger onTimeChange', this._currentTime)
      this.trigger(TimelineEvent.onTimeChange, this._currentTime)
      this.setRangeCurrentTime()
    }
  }

  /**
   * Sets the X position of the current radar time marker.
   * @param {String} currentTime: Current time as a datestring 'yyyyMMddHHmmss'.
   */
  setRangeCurrentTime () {
    this._debug && console.log('[timeline] setRangeCurrentTIme', this._currentTime, this._xScaleZoom(timeParser((this._currentTime))))
    let selectedTime = this._gRange.selectAll('.selected-time')

    // Select or create the "selected time" object.
    if (selectedTime.empty()) {
      selectedTime = this._gRange.append('line')
        .attr('class', 'selected-time')
        .attr('y1', 0)
        .attr('y1', this._height)
    }

    // Set its position.
    const x = this._xScaleZoom(timeParser((this._currentTime)))
    selectedTime.attr('x1', x)
    selectedTime.attr('x2', x)
  }

  onClickOnTimelineActiveArea (event) {
    this._debug && console.log('[timeline] onClickOnTimelineActiveArea click on timeline !')
    const clickedDatestring = timeFormatter(this._xScaleZoom.invert(event.x))
    // this.props.setCurrentTime(clickedDatestring)
    this._debug && console.log('[timeline] onClickOnTimelineActiveArea Trigger onTimeChange', clickedDatestring)
    this.trigger(TimelineEvent.onTimeChange, clickedDatestring)
  }

  /**
   * Mode.Single methods
   */

  onSingleTimeMove (event) {
    this._debug && console.log('[timeline] onSingleTimeMove')
    const { selection, type } = event

    // Hide handlers and rangeTips if no range selection
    if ((selection === null || typeof selection === 'undefined') && this._singleTip) {
      this._singleTip.attr('display', 'none')
      return
    }

    if (selection === null || typeof selection === 'undefined') {
      if (event.sourceEvent) {
        select('.brush-single').call(
          this._brushCurrentTime.move,
          [-1, event.sourceEvent.x]
        )
      }
      return
    }

    const newRangeDates = selection.map(this._xScaleZoom.invert)
    const newCurrentDatestring = timeFormatter(newRangeDates[1])
    const singleTipText = (
      this._isLive
        ? 'LIVE'
        : (
          (event.sourceEvent === null || typeof event.sourceEvent === 'undefined')
            ? tipTimeFormatter(timeParser(this._currentTime))
            : tipTimeFormatter(newRangeDates[1])
        )
    )

    // We compare new currentTime without seconds
    if (
      type === 'end' &&
      this._currentTime?.substring(0, 12) !== newCurrentDatestring.substring(0, 12)
    ) {
      // Early returns if no event selected
      if (
        event.sourceEvent === null ||
        typeof event.sourceEvent === 'undefined'
      ) {
        // Ensure tooltip keeps displaying current date when no brushing occured from user.
        this._singleTip.text(singleTipText)

        return
      }

      this._currentTimeCoords = selection
      if (
        this._isActive &&
         parseInt(this._maximumTimeAvailable) - parseInt(newCurrentDatestring) <= 300
      ) {
        this._debug && console.log('[timeline] onSingleTimeMove Trigger onClickOutsideFuture')
        this.trigger(TimelineEvent.onClickOutsideFuture)
      } else {
        this._debug && console.log('[timeline] onSingleTimeMove Trigger onTimeChange', newCurrentDatestring)
        this.trigger(TimelineEvent.onTimeChange, newCurrentDatestring)
      }
    }

    if (this._singleTip) {
      // Update this._singleTip values
      this._singleTip.attr('display', null)
        .attr('transform', `translate(${selection[1]}, -2)`)
        .attr('text-anchor', 'middle')
        .text(singleTipText)
    }

    // Update handlers position
    this._handleCurrent
      .attr('display', null)
      .attr('transform', `translate(${selection[1] - 5}, 0)`)
  }

  // buildSingleTimeBrush () {
  updateSingleTimeBrush () {
    // If mode isn't 'range', hide brush
    if (this._mode !== Mode.Single) {
      this._containerSingle.select('.brush-single').remove()
      return
    }

    this._debug && console.log('[timeline] updateSingleTimeBrush',
      this._mode,
      this._currentTime,
      this._gSingle,
      this._brushCurrentTime,
      this._containerSingle.select('.brush-single').empty()
    )

    // Remove any existing selection brush.
    if (
      this._containerSingle.select('.brush-single') &&
      !this._containerSingle.select('.brush-single').empty()
    ) {
      this._gSingle.call(this._brushCurrentTime.move, null)
      this._containerSingle.select('.brush-single').remove()
    }

    const brushSingle = this._containerSingle.select('.brush-single')

    // to not have a transition effect on zoom or timeline move
    // we try to move now the brush and return if ok
    if (
      !brushSingle.empty() &&
      this._gSingle &&
      this._currentTime
    ) {
      this._gSingle.call(
        this._brushCurrentTime.move,
        [-1, this._xScaleZoom(timeParser(this._currentTime))]
      )
      return
    }

    // Paint brush (selection)
    this._brushCurrentTime = brushX()
      .extent([[this._dataRange[0], 0], [this._dataRange[1], this._height]])
      .on('start brush end', e => {
        if (e.sourceEvent) {
          e.sourceEvent.stopImmediatePropagation()
        }
        this.onSingleTimeMove(e)
      })

    if (this._containerSingle.select('.brush-single').empty()) {
      this._gSingle = this._containerSingle.append('g')
        .attr('class', 'brush-single')

      /**
       * Add currentTime label on single handle
       */
      this._singleTip = this._gSingle
        .append('text')
        .attr('class', '_singleTip')
      // .attr('transform', `translate(12, ${this._height / 2 + 2})`)
        .text('')

      // Selection handlers
      this._handleCurrent = this._gSingle.selectAll('.handle--custom')
        .data([{ type: 'w' }, { type: 'e' }])
        .enter().append('g')
        .attr('class', 'handle-current-group')
        .attr('id', 'brush-single')

      // Create move handler SVG
      this._gSingle.selectAll('.handle-current-group')
        .append('rect')
        .attr('x', -10)
        .attr('y', 0)
        .attr('width', 20)
        .attr('height', this._height)
        .attr('cursor', 'ew-resize')
      this._gSingle.selectAll('.handle-current-group')
        .append('rect')
      // .attr('rx', 2)
      // .attr('ry', 2)
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', 4)
        .attr('height', this._height)
        .attr('class', 'selection-single')
        .attr('cursor', 'ew-resize')

      this._gSingle.selectAll('.handle-current-group')
        .append('circle')
        .attr('cx', 2)
        .attr('cy', this._height / 2 - 1)
        .attr('r', 6)
        .attr('stroke', '#fff')
        .attr('cursor', 'ew-resize')

      this._timelineActiveArea.on('click', e => this.onClickOnTimelineActiveArea(e))
    }
    this._gSingle.call(this._brushCurrentTime)
    // Removes selection area
    this._gSingle.selectAll('.brush-single>.selection').remove()
    // Remove overlay to allow use of range + currentTime brushes together
    this._gSingle.selectAll('.brush-single>.overlay').remove()
    // Remove start handle.
    this._gSingle.selectAll('.brush-single>.handle--w').remove()

    // Set current playing time position
    if (this._currentTime) {
      // this.trigger(TimelineEvent.onTimeChange, this._currentTime)
      this.setSingleCurrentTime()
    }
  }

  setSingleCurrentTime () {
    // If mode isn't 'range', continue
    if (this._mode !== Mode.Single) {
      return
    }
    this._debug && console.log('[timeline]', this._currentTime, this._xScaleZoom(timeParser(this._currentTime)))
    if (!this._currentTime || !this._gSingle) return
    this._gSingle.transition().call(
      this._brushCurrentTime.move,
      [-1, this._xScaleZoom(timeParser(this._currentTime))]
    )
  }

  /**
   * Period of Interests methods
   */

  updatePOIs () {
    this._debug && console.log('[timeline] update pois')
    // Remove existing available ranges if any.
    this._containerPOIs.selectAll('.poi').remove()

    if (!(this._pois?.length > 0)) {
      return
    }

    this._pois.forEach((poi: PeriodOfInterest) => {
      const [sessionName] = poi.label.split('-')
      const startX = this._xScaleZoom(timeParser(poi.start))
      const endX = this._xScaleZoom(timeParser(poi.end))
      const width = (endX - startX) < 0 ? 0 : (endX - startX)
      const y = this._height - 28
      const recCenter = width ? startX + (width / 2) : startX
      const title = 'Session ' + sessionName + ' (' +
      tipTimeFormatter(timeParser(poi.start)) + ' - ' +
      tipTimeFormatter(timeParser(poi.end)) + ')'

      this._containerPOIs.append('rect')
        .attr('class', 'poi')
        .attr('x', startX)
        .attr('y', y)
        .attr('width', width)
        .attr('height', 6)
        .attr('style', 'cursor: pointer')
        .on('click', () => {
          this.trigger(TimelineEvent.onClickPOI, poi)
        })
        .append('title')
        .text(title)

      this._containerPOIs.append('text')
        .attr('class', 'poi')
        .attr('x', recCenter)
        .attr('y', y - 5)
        .attr('text-anchor', 'middle')
        .text(sessionName)
        .attr('style', 'cursor: pointer')
        .on('click', () => {
          this.trigger(TimelineEvent.onClickPOI, poi)
        })
        .append('title')
        .text(title)
    })
  }
}
